<template>
  <div class="box autoRouterParent">
    <div class="header">
      <div class="item left">
        <img :src="headerLogo" class="logo-img" alt="" srcset="">
        <p>{{systemName}}客服调度平台</p>
      </div>
      <audio src="@/assets/auido.mp3" ref="audio" style="width:0;height:0;" preload controls>您的浏览器不支持 audio 标签。</audio>
      <div class="item right">
        <div class="right-item">
          <router-link to="/branch">
            <p><i class="el-icon-user"></i>工作台</p>
          </router-link>
        </div>
        <div class="right-item">
          <el-dropdown trigger="click">
            <p><i class="el-icon-time"></i>工单处理</p>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <router-link to="/workorder/revisit">
                  <p style="padding:3px 5px;"><i class="el-icon-connection"></i>回访工单（{{countData.returnVisit}}）</p>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/workorder/polling">
                  <p style="padding:3px 5px;"><i class="el-icon-view"></i>巡检工单（{{countData.inspection}}）</p>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/workorder/repair">
                  <p style="padding:3px 5px;"><i class="el-icon-setting"></i>维修工单（{{countData.repair}}）</p>
                </router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="right-item">
          <el-dropdown trigger="click" @command="handleDropdownCommand">
            <p><i class="el-icon-document-copy"></i>全部事项<span v-if="messageCount">（+{{messageCount}}）</span></p>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <router-link to="/matter/consult">
                  <p style="padding:3px 5px;"><i class="el-icon-phone-outline"></i>来电咨询（{{countData.consulting}}）</p>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/matter/complaint">
                  <p style="padding:3px 5px;"><i class="el-icon-warning-outline"></i>意见投诉（{{countData.complaint}}）</p>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/matter/edit">
                  <p style="padding:3px 5px;"><i class="el-icon-edit-outline"></i>车辆绑定申请（{{countData.edit}}）</p>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/matter/appeal">
                  <p style="padding:3px 5px;"><i class="el-icon-edit-outline"></i>车辆申诉（{{countData.appeal}}）</p>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/matter/repairs">
                  <p style="padding:3px 5px;"><i class="el-icon-cpu"></i>设备报修（{{countData.reportRepair}}）</p>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/matter/collection">
                  <p style="padding:3px 5px;"><i class="el-icon-money"></i>欠款催收（{{countData.collection}}）</p>
                </router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="right-item">
          <el-dropdown trigger="click">
            <p><i class="el-icon-notebook-2"></i>快捷处理</p>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <p style="padding:3px 5px;" @click="showDialog('consultation')"><i class="el-icon-connection"></i>添加来电咨询记录</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p style="padding:3px 5px;" @click="showDialog('complaint')"><i class="el-icon-chat-dot-square"></i>添加来电投诉记录</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p style="padding:3px 5px;" @click="showDialog('repairs')"><i class="el-icon-setting"></i>添加来电报修记录</p>
              </el-dropdown-item>
              <!-- <el-dropdown-item>
                <p style="padding:3px 5px;" @click="showDialog('order')"><i class="el-icon-warning-outline"></i>添加异常订单记录
                </p>
              </el-dropdown-item> -->
              <el-dropdown-item>
                <p style="padding:3px 5px;" @click="showDialog('message')"><i class="el-icon-thumb"></i>新建消息推送</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p style="padding:3px 5px;" @click="showDialog('open')"><i class="el-icon-set-up"></i>紧急降锁指令</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p style="padding:3px 5px;" @click="showDialog('gate')"><i class="el-icon-guide"></i>紧急开闸指令</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="right-item">
          <el-dropdown trigger="click">
            <p><i class="el-icon-document-copy"></i>订单中心</p>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <router-link to="/order/list">
                  <p style="padding:3px 5px;"><i class="el-icon-document-copy"></i>所有订单</p>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/order/arrears">
                  <p style="padding:3px 5px;"><i class="el-icon-money"></i>欠费订单</p>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/order/forensics">
                  <p style="padding:3px 5px;"><i class="el-icon-camera"></i>已取证订单</p>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/order/error">
                  <p style="padding:3px 5px;"><i class="el-icon-warning-outline"></i>异常订单</p>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/order/appointment">
                  <p style="padding:3px 5px;"><i class="el-icon-time"></i>预约订单</p>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/order/noplate">
                  <p style="padding:3px 5px;"><i class="el-icon-document-delete"></i>无牌订单</p>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/order/errorSeat">
                  <p style="padding:3px 5px;"><i class="el-icon-cpu"></i>异常车位</p>
                </router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="right-item">
          <p @click="showIvr=!showIvr"><i class="el-icon-service"></i>软电话</p>
        </div>
        <div class="right-item">
          <p @click="showFaq=true"><i class="el-icon-notebook-1"></i>话术FAQ</p>
        </div>
        <div class="right-item">
          <el-dropdown>
            <p><i class="el-icon-user"></i>{{$store.state.userInfo.nickName?`【${$store.state.userInfo.nickName}】`:"个人中心"}}</p>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <router-link to="/branch">
                  <p style="padding:3px 5px;"><i class="el-icon-monitor"></i>我的工作台</p>
                </router-link>
              </el-dropdown-item>
              <!-- <el-dropdown-item>
                <router-link to="/message">
                  <p style="padding:3px 5px;"><i class="el-icon-bell"></i>我的消息</p>
                </router-link>
              </el-dropdown-item> -->
              <el-dropdown-item>
                <p style="padding:3px 5px;" @click="quitLogin"><i class="el-icon-switch-button"></i>退出登录</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="container">
      <el-card :class="showIvr?'branch':'branch branch-hidden'">
        <p class="title">通话信息</p>
        <template v-if="callerInfo.mobile">
          <p class="label"><span>通话类型：</span>{{callerInfo.type}}</p>
          <p class="label"><span>通话号码：</span>{{callerInfo.mobile}}</p>
          <p class="label"><span>用户姓名：</span>{{callerInfo.username}}</p>
          <p class="label">
            <span v-if="callerInfo.plateNumberList&&callerInfo.plateNumberList.length==0">该用户暂未绑定车牌</span>
            <el-tag style="margin-right:5px;" type="success" v-for="(it,key) in callerInfo.plateNumberList" :key="key">{{it}}</el-tag>
          </p>
          <p class="label"><span>所在地区：</span>{{callerInfo.address}}</p>
          <p class="label"><span>历史投诉：</span>{{callerInfo.complaintNum}}</p>
          <p class="label"><span>历史咨询：</span>{{callerInfo.consultationNum}}</p>
          <p class="label"><span>历史报修：</span>{{callerInfo.deviceRepairNum}}</p>
          <p class="label"><span>订单异常：</span>{{callerInfo.orderAbnormalNum}}</p>
        </template>
        <template v-else>
          <div class="no-caller">
            <p>暂无来电</p>
          </div>
        </template>
        <div class="h15"></div>
        <div id="ali-crm"></div>
      </el-card>
      <keep-alive include="parkList">
        <router-view class="content"></router-view>
      </keep-alive>
    </div>

    <el-drawer title="话术FAQ查询" :visible.sync="showFaq" direction="rtl" :modal="false" size="380px">
      <div style="padding:0 15px;">
        <el-form inline>
          <el-form-item label="关键词">
            <el-input v-model="question" clearable placeholder="请输入问题关键词"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="selectFAQList(question)">查询</el-button>
          </el-form-item>
        </el-form>
        <div style="margin-bottom:15px;" v-if="faqHistoryList.length>0">
          <span>搜索记录：</span>
          <el-tag type="info" class="max-tag" v-for="(it,key) in faqHistoryList" :key="key" @click="question=it;selectFAQList(question)">{{it}}</el-tag>
          <el-button type="text" @click="deleteHistory">清除记录</el-button>
        </div>
        <div>
          <div v-for="(it,key) in faqList" :key="key" class="faqlist">
            <p>{{it.question}}</p>
            <p>{{it.answer}}</p>
          </div>
          <div style="text-align:center;padding:20px 0;">
            <el-button v-if="question" type="primary" plain @click="addToFaq">将此问题入库</el-button>
          </div>
        </div>
      </div>
    </el-drawer>

    <el-dialog :title="dialogData.title" :visible.sync="dialogData.show" :width="dialogData.width" :modal="false" :close-on-click-modal="false" :modal-append-to-body="false">
      <record-repairs v-if="dialogData.type=='repairs'"></record-repairs>
      <record-consultation v-if="dialogData.type=='consultation'"></record-consultation>
      <record-complaint v-if="dialogData.type=='complaint'"></record-complaint>
      <record-message v-if="dialogData.type=='message'"></record-message>
      <record-open v-if="dialogData.type=='open'"></record-open>
      <record-gate v-if="dialogData.type=='gate'"></record-gate>
      <record-order v-if="dialogData.type=='order'" :data="dialogData.data" :callback="dialogData.callback">
      </record-order>

      <workorder-revisit v-if="dialogData.type=='revisit'" :data="dialogData.data" :callback="dialogData.callback">
      </workorder-revisit>
      <workorder-repair v-if="dialogData.type=='repair'" :data="dialogData.data" :callback="dialogData.callback">
      </workorder-repair>
      <workorder-polling v-if="dialogData.type=='polling'" :data="dialogData.data" :callback="dialogData.callback">
      </workorder-polling>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "autoRouterParent",
  components: {
    //新增工单
    "workorder-repair": () =>
      import("@/views/autoRouter/create/workorder/repair.vue"),
    "workorder-polling": () =>
      import("@/views/autoRouter/create/workorder/polling.vue"),
    "workorder-revisit": () =>
      import("@/views/autoRouter/create/workorder/revisit.vue"),
    //新增记录
    "record-consultation": () =>
      import("@/views/autoRouter/create/record/consultation.vue"),
    "record-complaint": () =>
      import("@/views/autoRouter/create/record/complaint.vue"),
    "record-message": () =>
      import("@/views/autoRouter/create/record/message.vue"),
    "record-repairs": () =>
      import("@/views/autoRouter/create/record/repairs.vue"),
    "record-order": () => import("@/views/autoRouter/create/record/order.vue"),
    "record-open": () => import("@/views/autoRouter/create/record/open.vue"),
    "record-gate": () => import("@/views/autoRouter/create/record/gate.vue"),
  },
  data() {
    return {
      headerLogo: window._env.VUE_APP_PC_HEADER_LOGO,
      systemName: window._env.VUE_APP_PROJECT_NAME,
      caller: "",
      callerInfo: {},
      showIvr: true,
      showFaq: false,
      question: "",
      faqHistoryList: [],
      faqList: [],
      dialogData: {
        title: "",
        width: "500px",
        type: "message",
        show: false,
        data: {},
      },
      workbench: null,
      countData: {
        appeal: 0,
        collection: 0,
        complaint: 0,
        consulting: 0,
        inspection: 0,
        repair: 0,
        reportRepair: 0,
        returnVisit: 0,
      },
      websocket: null,
      messageCount: 0,
    };
  },
  watch: {
    showFaq: function (value) {
      if (value) {
        if (localStorage.question) {
          let history = localStorage.question;
          this.faqHistoryList = history.split(",");
        }
      }
    },
    $route: function () {
      this.selectWorkCountList();
    },
    "$store.state.caller": function (value) {
      if (!value) {
        this.caller = "";
        this.callerInfo = {};
      }
    },
  },
  created() {
    this.$http.post(this.$api.getOneServicer, null, (res) => {
      this.$store.commit("userInfo/setUserInfo", res);
      this.selfForensicsInfo(res.id);
    });
    this.$root.$on("showWorkorderDialog", (data) => {
      this.showDialog(data.type, data.data, data.callback);
    });
    this.$root.$on("closeWorkorderDialog", () => {
      Object.assign(this.$data.dialogData, this.$options.data().dialogData);
    });
    this.$root.$on("outbound", (mobile) => {
      this.showIvr = true;
      this.$message.success(`开始调用阿里云坐席拨打：${mobile}`);
      this.workbench.call(mobile);
    });
  },
  mounted() {
    this.initWorkbenchSdk();
    this.selectWorkCountList();
  },
  methods: {
    selectCustomerInfo(mobile, type) {
      this.$http.post(
        this.$api.selectCustomerInfo,
        {
          consultType: 1,
          mobile: mobile,
        },
        (res) => {
          this.callerInfo = res;
          switch (type) {
            case "1":
              this.callerInfo.type = "咨询";
              this.showDialog('consultation')
              break;
            case "2":
              this.callerInfo.type = "报修";
              this.showDialog('repairs')
              break;
            case "3":
              this.callerInfo.type = "订单异常";
              break;
            case "4":
              this.callerInfo.type = "投诉";
              this.showDialog('complaint')
              break;
            case "out":
              this.callerInfo.type = "外呼去电";
              break;
            default:
              this.callerInfo.type = "其他";
              break;
          }
        }
      );
    },
    selectWorkCountList() {
      this.$http.get(this.$api.selectWorkCountList, null, (res) => {
        this.countData = res;
      });
    },
    quitLogin() {
      this.$confirm("您确定要退出登录吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.post(this.$api.sysUserlogout, null, (res) => {
            this.$router.replace("/login");
          });
        })
        .catch(() => {});
    },
    selectFAQList(question) {
      if (question) {
        if (localStorage.question) {
          let history = localStorage.question;
          if (history.indexOf(question) == -1) {
            history = `${history},${question}`;
            localStorage.question = history;
          }
        } else {
          localStorage.question = question;
        }
      }
      this.$http.post(
        this.$api.selectFAQList,
        {
          currentPage: 1,
          pageSize: 10,
          question: question,
        },
        (res) => {
          if (res.list.length == 0) {
            this.$message.info("该问题暂无标准话术。");
          }
          this.faqList = res.list;
        }
      );
    },
    addToFaq() {
      this.$http.post(
        this.$api.insertFAQ,
        {
          answer: "回答待完善",
          question: this.question,
        },
        (res) => {
          this.$message.success("问题提交成功，待管理员审核完善。");
        }
      );
    },
    deleteHistory() {
      this.faqHistoryList = [];
      localStorage.clear("question");
    },
    showDialog(type, data, callback) {
      switch (type) {
        case "repairs":
          this.dialogData = {
            title: "添加报修记录",
            type: type,
            show: true,
            width: "500px",
          };
          break;
        case "repair":
          this.dialogData = {
            title: "新建维修工单",
            type: type,
            show: true,
            data: data,
            width: "500px",
            callback: callback,
          };
          break;
        case "polling":
          this.dialogData = {
            title: "新建巡检工单",
            type: type,
            show: true,
            data: data,
            width: "500px",
            callback: callback,
          };
          break;
        case "revisit":
          this.dialogData = {
            title: "新建回访工单",
            type: type,
            show: true,
            data: data,
            width: "500px",
            callback: callback,
          };
          break;
        case "message":
          this.dialogData = {
            title: "新建消息推送",
            type: type,
            show: true,
            width: "500px",
          };
          break;
        case "consultation":
          this.dialogData = {
            title: "添加来电咨询记录",
            type: type,
            show: true,
            width: "500px",
          };
          break;
        case "complaint":
          this.dialogData = {
            title: "添加来电投诉记录",
            type: type,
            show: true,
            width: "500px",
          };
          break;
        case "order":
          this.dialogData = {
            title: "添加异常订单记录",
            type: type,
            show: true,
            width: "500px",
            data: data,
            callback: callback,
          };
          break;
        case "open":
          this.dialogData = {
            title: "紧急下发开锁指令",
            type: type,
            show: true,
            width: "800px",
          };
          break;
        case "gate":
          this.dialogData = {
            title: "紧急下发开闸指令",
            type: type,
            show: true,
            width: "800px",
          };
          break;

        default:
          break;
      }
    },
    initWorkbenchSdk() {
      this.workbench = new WorkbenchSdk({
        dom: "ali-crm",
        header: false,
        instanceId: window._env.VUE_APP_ALI_INSTANCEID,
        regionId: "cn-shanghai",
        useOpenApiSdk: false,
        accessKeyId: localStorage.accessKeyId,
        accessKeySecret: localStorage.accessKeySecret,
        // RPCEndPoint: "https://ccc.cn-shanghai.aliyuncs.com",
        RPCEndPoint: "https://call.ichenggu.cn/aliccc",
        apiVersion: "aliyunrpc",
        afterCallRule: 15,
        exportErrorOfApi: false,
        moreActionList: [
          "onlyCall",
          "mobilePhoneAnswer",
          "break",
          "offline",
          "adjustVolume",
          "changeWorkMode",
        ],
        onInit: () => {
          this.workbench.register(); // 自动上线
        },
        onBeforeCallDialing: (payload) => {
          this.$store.commit("setCallerInfo", payload.callee);
          this.selectCustomerInfo(payload.callee, "out");
          payload.callback();
        },
        onErrorNotify: ({ errorCode, errorMsgTip }) => {
          this.$message.warning(`错误代码：${errorCode}-${errorMsgTip}`);
        },
        onCallComing: (callDetail) => {
          this.showIvr = true;
        },
        onCallEstablish: ({ callContext }) => {
          let callType = callContext.callType; //"INBOUND","OUTBOUND"
          let { originator, destination, channelVariables } =
            callContext.channelContexts[1];
          let keyData = channelVariables.charAt(channelVariables.length - 1);
          if (callType === "OUTBOUND") {
            this.callerInfo.type = "外呼去电";
            this.$store.commit("setCallerInfo", destination);
            this.selectCustomerInfo(destination, "out");
          }
          if (callType === "INBOUND") {
            this.$store.commit("setCallerInfo", originator);
            this.selectCustomerInfo(originator, keyData);
            this.$http.post(
              this.$api.insertCallCenterLog,
              {
                consultType: keyData,
                mobile: originator,
              },
              (res) => {}
            );
            if (this.$route.path != "/branch") {
              this.$router.push("/branch");
            }
          }
        },
        onCallRelease: (connid, caller, privateData) => {
          this.$store.commit("setCallerInfo", "");
        },
      });
    },
    selfForensicsInfo(userId) {
      let path = window._env.VUE_APP_BASEURL.replace("https", "wss");
      this.websocket = new WebSocket(
        `${path}park-service-system/websocket/${userId}`
      );
      this.websocket.onmessage = (e) => {
        if (e.data != "连接成功") {
          const message = JSON.parse(e.data);
          if (message.type === "deviceMessage") {
            this.$refs.audio.play();
            this.$notify.info({
              title: "系统消息",
              message: message.content,
              duration: 10000,
            });
          } else if (message.type === "todoMessage") {
            this.messageCount += message.content;
          }
        }
      };
      this.websocket.onerror = (e) => {
        this.websocket = null;
        setTimeout(() => {
          this.selfForensicsInfo(userId);
        }, 5000);
      };
      this.websocket.onclose = () => {
        this.websocket = null;
        setTimeout(() => {
          this.selfForensicsInfo(userId);
        }, 5000);
        this.$notify.error({
          title: "断开连接",
          message: "服务器已断开连接，正在尝试重连！",
        });
      };
    },
    handleDropdownCommand() {
      this.messageCount = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.autoRouterParent {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: $maincolor;
    color: #fff;
    padding: 0 20px;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .iconfont {
        font-size: 30px;
        cursor: pointer;
      }
      .logo-img {
        height: 40px;
        width: auto;
      }
      p {
        font-size: 24px;
        margin: 0;
        line-height: 1em;
      }
    }
    .right {
      font-size: 14px;
      display: flex;
      align-items: center;
      .right-item {
        cursor: pointer;
        height: 50px;
        padding: 0 10px;
        background-color: transparent;
        transition-duration: 0.5s;
        display: flex;
        align-items: center;
        &:hover {
          background-color: rgba(255, 255, 255, 0.315);
        }
        a {
          color: #fff;
        }
        p {
          margin: 0;
          line-height: 50px;
          i {
            margin-right: 3px;
          }
        }
        .label {
          margin-right: 3px;
          line-height: 50px;
        }
        .iconfont {
          margin-right: 6px;
        }
        /deep/.el-dropdown {
          color: #fff;
        }
      }
    }
  }
  .container {
    display: flex;
    height: calc(100% - 50px);
    padding: 15px;
    position: relative;
    overflow: hidden;
    .no-caller {
      background-color: #f8f8f8;
      color: #cacaca;
      text-align: center;
      padding: 170px 0;
    }
    .branch {
      width: 360px;
      height: calc(100% - 2px);
      margin-right: 15px;
      .title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
      .label {
        padding: 15px 15px 0 15px;
      }
      .caller {
        text-align: center;
        font-size: 15px;
      }
    }
    .branch-hidden {
      width: 0px;
      border: 0;
      margin-right: 0;
    }
    .content {
      flex: 1;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .max-tag {
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .faqlist {
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    p {
      position: relative;
      padding-left: 38px;
      margin-bottom: 5px;
      &:first-child {
        color: #333;
        &::before {
          position: absolute;
          z-index: 1;
          left: 0;
          content: "问题：";
        }
      }
      &:last-child {
        color: #999;
        &::before {
          position: absolute;
          z-index: 1;
          left: 0;
          content: "回答：";
        }
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>